import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import data from "../content/whyus.json"; // JSON content
import "../styles/WhyUsSection.css"; // CSS styles
import icon1 from "../assets/modular.png"; // Example icon import
import icon2 from "../assets/debugging.png";
import icon3 from "../assets/cyber-security.png";
import icon4 from "../assets/multi.png";

const icons = [icon1, icon2, icon3, icon4]; // Array of icons for easy access

const WhyUsSection = ({ language }) => {
  const navigate = useNavigate(); // Initialize navigate hook
  const content = data[language];
  const isArabic = language === "ar";
  const arabicClass = isArabic ? "arabic-text rtl" : "";

  const handleCardClick = () => {
    navigate("/services"); // Navigate to the "Services" page
  };

  return (
    <section className={`why-us-section ${arabicClass}`}>
      <h2 className={`why-us-title ${isArabic ? "arabic-centered" : ""}`}>
        {isArabic ? "ما الذي يميزنا عن غيرنا؟" : "What Sets Us Apart"}
      </h2>
      <div className="why-us-cards">
        {Object.keys(content).map((point, index) => (
          <Card
            key={index}
            title={content[point].title}
            description={content[point].description}
            icon={icons[index]} // Use the corresponding icon
            iconClass={`icon${index + 1}`} // Add icon class for background color
            arabicClass={arabicClass}
            onClick={handleCardClick} // Pass the click handler to the card
          />
        ))}
      </div>
    </section>
  );
};

const Card = ({
  title,
  description,
  icon,
  iconClass,
  arabicClass,
  onClick,
}) => {
  return (
    <div
      className={`why-us-card ${arabicClass} ${iconClass}`}
      onClick={onClick}
    >
      <img src={icon} alt="Icon" className={`why-us-icon ${arabicClass}`} />
      <h3 className={`why-us-card-title ${arabicClass}`}>{title}</h3>
      <p className={`why-us-card-description ${arabicClass}`}>{description}</p>
    </div>
  );
};

export default WhyUsSection;
