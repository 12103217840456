import React from "react";
import visionData from "../content/VisionMissionApproach.json";
import "../styles/VisionSection.css";

const VisionSection = ({ language }) => {
  const content = visionData[language];
  const isArabic = language === "ar";
  const arabicClass = isArabic ? "arabic-text rtl" : "";

  return (
    <section className={`vision-section ${arabicClass}`}>
      <div className="vision-content">
        {Object.keys(content).map((key) => (
          <div key={key} className="vision-item">
            <h2 className={`vision-title ${arabicClass}`}>
              {content[key].title}
            </h2>
            <p className={`vision-description ${arabicClass}`}>
              {content[key].description}
            </p>
            <p className={`vision-additional-info ${arabicClass}`}>
              {content[key].additionalInfo}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default VisionSection;
