import React, { useEffect, useState } from "react";
import "../styles/ExpertiseSection.css"; // Adjust the path as necessary
import expertiseContent from "../content/expertisecontent.json"; // Import the JSON file

const ExpertiseSection = ({ language }) => {
  const [lang, setLang] = useState(language || "en");

  useEffect(() => {
    setLang(language);
  }, [language]);

  const currentContent = expertiseContent[lang]; // Use the imported JSON data

  // Check if currentContent is defined
  if (!currentContent) {
    return (
      <section className={`expertise-section ${lang === "ar" ? "rtl" : ""}`}>
        <h2 className="expertise-title">Content Not Available</h2>
      </section>
    );
  }

  return (
    <section
      className={`expertise-section ${lang === "ar" ? "rtl arabic-text" : ""}`}
    >
      <h2 className="expertise-title">{currentContent.title}</h2>
      <ul className="expertise-items">
        {currentContent.items.map((item, index) => (
          <li key={index} className="expertise-item">
            {item}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ExpertiseSection;
