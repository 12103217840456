import React from "react";
import "../styles/Footer.css";
import linkedinIcon from "../assets/linkedin.svg";
import facebookIcon from "../assets/facebook.svg";
import instagramIcon from "../assets/instagram.svg";
import youtubeIcon from "../assets/youtube-logo.svg";
import xIcon from "../assets/x_logo.svg";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="social-links">
          <a
            href="https://www.linkedin.com/company/xpeditesolutions"
            target="_blank"
            rel="noopener noreferrer" // Comment outside JSX
            aria-label="LinkedIn"
            className="social-button linkedin"
          >
            <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61564686013702"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="social-button facebook"
          >
            <img src={facebookIcon} alt="Facebook" className="social-icon" />
          </a>
          <a
            href="https://www.instagram.com/xpeditesolutions/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
            className="social-button instagram"
          >
            <img src={instagramIcon} alt="Instagram" className="social-icon" />
          </a>
          <a
            href="https://www.youtube.com/@XpediteSolutions"
            target="_blank"
            rel="noopener noreferrer" // Comment outside JSX
            aria-label="Youtube"
            className="social-button linkedin"
          >
            <img src={youtubeIcon} alt="Youtube" className="social-icon" />
          </a>
          <a
            href="https://x.com/XpediteSolution"
            target="_blank"
            rel="noopener noreferrer" // Comment outside JSX
            aria-label="X"
            className="social-button linkedin"
          >
            <img src={xIcon} alt="X" className="social-icon" />
          </a>
        </div>
        <div className="contact-info">
          <p>
            Reach us at:{" "}
            <a href="mailto:info@xpeditesolutions.com">
              info@xpeditesolutions.com
            </a>
          </p>
        </div>
        <div className="copyright-info">
          <p>
            © {new Date().getFullYear()} Xpedite Solutions. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
