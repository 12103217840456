import React, { useState, useEffect, useRef } from "react";
import { Element } from "react-scroll";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import WhyUsSection from "./components/WhyUsSection";
import ServicesSection from "./components/ServicesSection";
import WhoAreWe from "./components/WhoAreWe";
import LogoCarousel from "./components/LogoCarousel";
import VisionSection from "./components/VisionSection";
import ExpertiseSection from "./components/ExpertiseSection";

function App() {
  const [language, setLanguage] = useState("en");
  const [activeItem, setActiveItem] = useState("home");
  const sectionsRef = useRef([]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("Intersecting:", entry.target.id); // Debugging
          setActiveItem(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "-20px 0px -70% 0px",
      threshold: [0.3, 0.7],
    });

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, [sectionsRef.current]);

  return (
    <div className="App">
      <Navbar
        language={language}
        setLanguage={setLanguage}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        sectionsRef={sectionsRef}
      />
      <Element name="home">
        <HeroSection
          ref={(el) => (sectionsRef.current[0] = el)}
          id="home"
          language={language}
        />
      </Element>
      <LogoCarousel />
      <Element name="why-us">
        <WhyUsSection
          ref={(el) => (sectionsRef.current[1] = el)}
          id="why-us"
          language={language}
        />
      </Element>
      <Element name="about">
        <WhoAreWe
          ref={(el) => (sectionsRef.current[2] = el)}
          id="about"
          language={language}
        />
      </Element>
      <Element name="vision">
        <VisionSection
          ref={(el) => (sectionsRef.current[3] = el)}
          id="vision"
          language={language}
        />
      </Element>
      <Element name="services">
        <ServicesSection
          ref={(el) => (sectionsRef.current[4] = el)}
          id="services"
          language={language}
        />
      </Element>
      <Element name="expertise">
        <ExpertiseSection
          ref={(el) => (sectionsRef.current[5] = el)}
          id="expertise"
          language={language}
        />
      </Element>
      <Footer />
    </div>
  );
}

export default App;
