import React, { useState, useEffect, useRef } from "react";
import { Link as ScrollLink } from "react-scroll";

import bannerMainImage from "../assets/banner-main-img.jpg";
import data from "../content/herosection.json";
import "../styles/HeroSection.css";

const HeroSection = ({ language }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 786);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 786);
    };

    updateIsMobile();

    window.addEventListener("resize", updateIsMobile);

    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const content = data[language];
  const isArabic = language === "ar";
  const arabicClass = isArabic ? "arabic-text rtl" : "";

  return (
    <>
      {isMobile ? (
        <MobileHero
          content={content}
          arabicClass={arabicClass}
          isArabic={isArabic}
          language={language}
        />
      ) : (
        <DesktopHero
          content={content}
          arabicClass={arabicClass}
          isArabic={isArabic}
          language={language}
        />
      )}
    </>
  );
};

const DesktopHero = ({ content, arabicClass, isArabic, language }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    adjustFontSize(titleRef.current);
  }, [titleRef]);

  return (
    <section className={`desktop-hero-section ${arabicClass}`}>
      <div
        className={`desktop-banner-v2-content-wrap ${isArabic ? "rtl" : ""}`}
      >
        <div
          className={`desktop-banner-v2-img-wrapper ${
            isArabic
              ? "desktop-banner-v2-img-left"
              : "desktop-banner-v2-img-right"
          }`}
        >
          <img
            src={bannerMainImage}
            alt="Main Banner"
            className="desktop-banner-v2-main-img"
          />
        </div>
        <div className="desktop-banner-v2-details-wrap">
          <h1
            className={`desktop-banner-title-v2-big ${arabicClass}`}
            ref={titleRef}
          >
            {content.title}
          </h1>
          <h2 className={`desktop-banner-title-v2-small ${arabicClass}`}>
            {content.tagline}
          </h2>
          <p className={`desktop-banner-details-v2 ${arabicClass}`}>
            {content.subheading}
          </p>
          <div
            className={`desktop-banner-btns-wrapper ${arabicClass} ${
              isArabic ? "button-align-right" : ""
            }`}
          >
            <ScrollLink
              to="services" // ID of the target section
              smooth={true}
              duration={500}
              offset={-100} // Adjust if needed
              className={`desktop-primary-button ${arabicClass}`}
            >
              {content.ctaButton}
            </ScrollLink>
          </div>
        </div>
      </div>
    </section>
  );
};

const MobileHero = ({ content, arabicClass, isArabic, language }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    adjustFontSize(titleRef.current);
  }, [titleRef]);

  return (
    <section className={`mobile-hero-section ${arabicClass}`}>
      <div className="mobile-banner-img-wrapper">
        <img
          src={bannerMainImage}
          alt="Main Banner"
          className="mobile-banner-img"
        />
        <div className="mobile-overlay"></div>
        <div className="mobile-text-overlay">
          <h1
            className={`mobile-banner-title-v2-big responsive-text ${arabicClass}`}
            ref={titleRef}
          >
            {content.title}
          </h1>
          <h2
            className={`mobile-banner-title-v2-small responsive-text ${arabicClass}`}
          >
            {content.tagline}
          </h2>
          <p className={`mobile-banner-details-v2 ${arabicClass}`}>
            {content.subheading}
          </p>
          <div
            className={`mobile-banner-btns-wrapper ${arabicClass} ${
              isArabic ? "button-align-right" : ""
            }`}
          >
            <ScrollLink
              to="services" // ID of the target section
              smooth={true}
              duration={500}
              offset={-100} // Adjust if needed
              className={`mobile-primary-button ${arabicClass}`}
            >
              {content.ctaButton}
            </ScrollLink>
          </div>
        </div>
      </div>
    </section>
  );
};

const adjustFontSize = (element) => {
  if (!element) return;

  const parentHeight = element.parentElement.clientHeight;
  let fontSize = parseFloat(window.getComputedStyle(element).fontSize);
  while (element.scrollHeight > parentHeight && fontSize > 10) {
    fontSize -= 1;
    element.style.fontSize = `${fontSize}px`;
  }
};

export default HeroSection;
