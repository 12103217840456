import React from "react";
import "../styles/LogoCarousel.css";

import logo1 from "../assets/automation/Picture1.png";
import logo2 from "../assets/automation/Picture2.png";
import logo3 from "../assets/automation/Picture3.png";
import logo4 from "../assets/automation/Picture4.png";
import logo5 from "../assets/automation/Picture5.png";
import logo6 from "../assets/automation/Picture6.png";
import logo7 from "../assets/automation/Picture7.png";
import logo8 from "../assets/automation/Picture8.png";
import logo9 from "../assets/automation/Picture9.png";
import logo10 from "../assets/automation/Picture10.png";

const logos = [
  { src: logo1, alt: "Logo 1" },
  { src: logo2, alt: "Logo 2" },
  { src: logo3, alt: "Logo 3" },
  { src: logo4, alt: "Logo 4" },
  { src: logo5, alt: "Logo 5" },
  { src: logo6, alt: "Logo 6" },
  { src: logo7, alt: "Logo 7" },
  { src: logo8, alt: "Logo 8" },
  { src: logo9, alt: "Logo 9" },
  { src: logo10, alt: "Logo 10" },
];

const LogoCarousel = () => {
  return (
    <div className="logo-carousel">
      <div className="logo-carousel-wrapper">
        {logos.map((logo, index) => (
          <img
            key={index}
            src={logo.src}
            alt={logo.alt}
            className="logo-carousel-icon"
          />
        ))}
        {logos.map((logo, index) => (
          <img
            key={index + logos.length} // Ensure unique key for the second set
            src={logo.src}
            alt={logo.alt}
            className="logo-carousel-icon"
          />
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;
