import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import navbarContent from "../content/navbar.json";
import threedots from "../assets/threedots.svg";
import closeIcon from "../assets/closeIcon.svg";
import logo from "../assets/logo_main.svg";
import "../styles/Navbar.css";

const Navbar = ({ language, setLanguage, activeItem, setActiveItem }) => {
  const [content, setContent] = useState(navbarContent.en);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    setContent(navbarContent[language]);
  }, [language]);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 786);
    };

    updateIsMobile();
    window.addEventListener("resize", updateIsMobile);

    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  return (
    <nav
      className={isMobile ? "navbar mobile-navbar" : "navbar desktop-navbar"}
    >
      <div className="container">
        <Logo setActiveItem={setActiveItem} />
        {isMobile ? (
          <MobileNavbar
            content={content}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            setLanguage={setLanguage}
            language={language}
          />
        ) : (
          <DesktopNavbar
            content={content}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            setLanguage={setLanguage}
            language={language}
          />
        )}
        <LanguageSelector language={language} setLanguage={setLanguage} />
      </div>
    </nav>
  );
};

const Logo = ({ setActiveItem }) => (
  <div className="logo-container">
    <ScrollLink
      to="home"
      smooth={true}
      duration={500}
      offset={-100}
      className="logo-link"
      onSetActive={() => setActiveItem("home")}
    >
      <img src={logo} alt="Logo" className="logo logo-image" />
    </ScrollLink>
  </div>
);

const LanguageSelector = ({ language, setLanguage }) => (
  <div className="language-toggle">
    <span
      className={language === "en" ? "active-language" : ""}
      onClick={() => setLanguage("en")}
    >
      EN
    </span>
    {" | "}
    <span
      className={language === "ar" ? "active-language" : ""}
      onClick={() => setLanguage("ar")}
    >
      AR
    </span>
  </div>
);

const DesktopNavbar = ({ content, activeItem, setActiveItem, language }) => {
  const navItems = Object.keys(content.navItems).sort(
    (a, b) => content.navOrder.indexOf(a) - content.navOrder.indexOf(b)
  );

  const displayedNavItems = language === "ar" ? navItems.reverse() : navItems;

  return (
    <ul className="nav-links">
      {displayedNavItems.map((key) => (
        <li key={key} className="nav-item">
          <ScrollLink
            to={key}
            smooth={true}
            duration={500}
            offset={-100}
            className={`link desktop-nav-item ${
              activeItem === key ? "active-link" : ""
            } ${language === "ar" ? "arabic-text" : ""}`}
            onSetActive={() => setActiveItem(key)}
          >
            {content.navItems[key]}
          </ScrollLink>
        </li>
      ))}
    </ul>
  );
};

const MobileNavbar = ({
  content,
  activeItem,
  setActiveItem,
  setLanguage,
  language,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <>
      <div className="mobile-header">
        <div
          className={`menu-icon ${menuOpen ? "menu-icon-open" : ""}`}
          onClick={toggleMenu}
        >
          <img
            src={menuOpen ? closeIcon : threedots}
            alt="Menu"
            className="icon-image"
          />
        </div>
      </div>
      <div
        className="overlay"
        style={{
          display: menuOpen ? "block" : "none",
          animation: menuOpen
            ? "slideDownMenu 0.3s ease forwards"
            : "slideUpMenu 0.3s ease forwards",
        }}
      >
        <ul
          className="nav-links-open"
          style={{
            textAlign: language === "ar" ? "right" : "left",
          }}
        >
          {Object.keys(content.navItems).map((key) => (
            <li key={key} className="nav-item">
              <ScrollLink
                to={key}
                smooth={true}
                duration={500}
                offset={-100}
                className={`link-mobile  ${
                  activeItem === key ? "active-link" : ""
                } ${language === "ar" ? "arabic-text" : ""}`}
                onSetActive={() => setActiveItem(key)}
              >
                {content.navItems[key]}
              </ScrollLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
